import { render, staticRenderFns } from "./PackElForm.vue?vue&type=template&id=08b67d5e&scoped=true"
import script from "./PackElForm.vue?vue&type=script&lang=js"
export * from "./PackElForm.vue?vue&type=script&lang=js"
import style0 from "./PackElForm.vue?vue&type=style&index=0&id=08b67d5e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b67d5e",
  null
  
)

export default component.exports